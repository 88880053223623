#footing, #home {
  overflow: hidden;
}

#home {
  background: #1E1E44;
}

.heading {
    z-index: 2;
    position: fixed;
    top: 0px;
    height: 85px;
    width: 100%;
    background-color: #1E1E44;
}

.onMobile {
  display: none;
}

.register {
  margin-top: 12px;
  text-transform: none;
}

.heading_buttons {
  color: white;
  font-size: 50px;
  text-decoration: none;
  position: absolute;
  bottom: 8px;
  /* top: 12px; */
  left: 100px;
}

.footing {
  position: relative;
  bottom: 10px;
  right: 30px;
}

.triangle_background {
    width: 200px;
    height: 200px;
    border-style: solid;
    border-color: white;
    left: 0px;
  }
  
  .triangle-up-big {
    width: 0;
    height: 0;
    position: absolute;
    top: 34px;
    left: 34px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 33.5px solid white;
    cursor: pointer;
  }
  
  
  .triangle-up-small {
    width: 0;
    height: 0;
    position: absolute;
    top: 42.5px;
    left: 66.5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 25px solid white;
    cursor: pointer;
  }
  
  .triangle-down {
    width: 0;
    height: 0;
    position: absolute;
    top: 18px;
    left: 45px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid white;
    cursor: pointer;
  }

  .animate-site-container {
    animation-delay: .2s;
    animation-duration: 1.7s;
    animation-fill-mode: both;
  }
  
  /* fadeIn
  ------------------------------------------- */
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  /* fadeInUp
  ------------------------------------------- */
  
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(70px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  
  /* fadeInDown
  ------------------------------------------- */
  
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

  .mountain {
    background-image: url('../static_pages/optimized.png');
    display: table;
    /*filter: grayscale(90%) brightness(50%); */
    height: 100vh;
    width: 100%;
    position: relative;
    background-size: cover;
    align-items: center;
    object-fit: cover;
  }

  .footer {
    background-color: #d3d3d3;
     color: black;
      height: 100px;
  }

  #landing-text {
    position: absolute;
    text-align: center;
    width: 100%;
    margin: auto;
    top: 36vh;
  }

  .words {
    color: white;
    font-size: 50px;
    line-height: 65px;
    font-weight: normal;
    letter-spacing: 2px;
  }

  @media (max-width: 600px) {
    .triangle-up-big {
      left: calc(47vw - 12px);
      /* top: 35.5vh; */
      top: -6vh;
    }
    .triangle-up-small {
      left: calc(47vw + 22px);
      /* top: calc(35.5vh + 8.5px) */
      top: calc(-6vh + 8.5px)
    }
    .triangle-down {
      left: 47vw;
      /* top: calc(35.5vh - 15px); */
      top: calc(-6vh - 15px)
    }

    #landing-text {
      top: 40vh;
    }

    .offMobile {
      display: none;
    }

    .onMobile {
      position: 'relative';
      display: block;
    }

    .register {
      display: none;
    }

    .heading_buttons {
      font-size: 40px;
      top: 19px;
      left: 20px;
      position: relative;
    }

    .heading {
      background-color: transparent;
    }

    .footing {
      margin-left: 8%;
      margin-right: 8%;
      width: 70%;
    }

    #text-break {
      display: none;
    }

    .words {
      font-size: 32px;
      font-weight: normal;
      letter-spacing: .5px;
      margin-right: 6px;
      margin-left: 6px;
      line-height: 30px;
      transition: none;
      transform: none;
    }

  }

  .logo {
    width: 100px;
    filter: opacity(30%)
  }

  /* animation for sliding in on scroll */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  /*clearfixes*/
  
  .cf:before,
  .cf:after {
    content: " ";
    display: table;
  }
  
  .cf:after {
    clear: both;
  }
  
  .main-container {
    background: #fff;
    max-width: 1000px;
    margin: 25px auto 25px auto;
    position: relative;
  }
  
  .container {
    position: relative;
    padding: 25px;
  }
  /*animation element*/
  
  .animation-element {
    opacity: 0;
    position: relative;
  }

  .animation-element.slide-left {
    opacity: 0;
    -moz-transition: all 500ms linear .2s;
    -webkit-transition: all 500ms linear .2s;
    -o-transition: all 500ms linear .2s;
    transition: all 500ms linear .2s;
    -moz-transform: translate3d(-100px, 0px, 0px);
    -webkit-transform: translate3d(-100px, 0px, 0px);
    -o-transform: translate(-100px, 0px);
    -ms-transform: translate(-100px, 0px);
    transform: translate3d(0px, 100px, 0px);
  }
  
  .animation-element.slide-left.in-view {
    opacity: 1;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
  /*animation slide left styled for testimonials*/
  
  /* .animation-element.slide-left.testimonial {
    margin: 0% 1.5% 3% 1.5%;
    padding: 15px;
  } */
  /* .animation-element.slide-left.testimonial:hover,
  .animation-element.slide-left.testimonial:active{
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  } */
  
  /* .animation-element.slide-left.testimonial:nth-of-type(odd) {
    margin: 0% 1.5% 3.0% 0%;
  }
  
  .animation-element.slide-left.testimonial:nth-of-type(even) {
    margin: 0% 0% 3.0% 1.5%;
  } */
  
  .animation-element.slide-left.testimonial .header{
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  .animation-element.slide-left.testimonial .left{
    float: left;
    margin-right: 15px;
  }
  .animation-element.slide-left.testimonial .right{
    float: left;
  }
  
  .animation-element.slide-left.testimonial h3 {
    margin: 0px 0px 5px 0px;
  }
  
  .animation-element.slide-left.testimonial h4 {
    margin: 0px 0px 5px 0px;
  }
  
  .animation-element.slide-left.testimonial .content {
    float: left;
    width:100%;
    margin-bottom: 10px;
  }
  
  .animation-element.slide-left.testimonial .rating{}
  
  .animation-element.slide-left.testimonial i {
    color: #aaa;
    margin-right: 5px;
  }
  
  
