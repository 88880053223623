.flexible-modal {
    position: absolute;
    z-index: 2000;
    border: 1px solid #ccc;
    background: white;
    border-radius: 3px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  /* .flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;
  } */
  .flexible-modal-resizer {
    position: sticky;
    left: 100%;
    bottom:0;
    cursor:se-resize;
    margin:5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
  }
  .flexible-modal-drag-area{
    background: rgba(22, 22, 333, 0.2);
    height: 20px;
    position:absolute;
    right:0;
    top:0;
    cursor:move;
  }

  .my-modal-custom-class{
      background-color: white;
  }

  .flexible-modal > .flexible-modal-drag-area-right {
    height: 0px !important;
  }

  .flexible-modal > .flexible-modal-drag-area-left {
    height: 0px !important;
}
