.contact_footer {
    background-image: url('../static_pages/photo2.png');
    display: table;
    /*filter: grayscale(90%) brightness(50%); */
    height: 70vh;
    width: 100%;
    position: relative;
    background-size: cover;
    align-items: center;
    object-fit: cover;
}
