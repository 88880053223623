.number_box {
    margin-right: 30px;
    height: 40px;
    background: none;
    border: .5px solid grey;
    border-radius: 4px;
}

.helper_text {
    padding-top: 10px;
    padding-bottom: 20px;
}

.dialog_title {
    font-size: 80;
    color: red;
    font-weight: bold;
}
