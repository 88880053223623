/* .login_icon {
    position: relative;
    z-index: 3;
} */

.login {
    float: right;
    text-transform: none;
    bottom: 2;
}

.contact_icon {
    position: relative;
    top: calc(3vh / 2);
    left: 400px;
    z-index: 3;
}

@media (max-width: 600px) {
    
    .login {
        display: none;
    }

    .contact {
        display: none;
    }
}

.nav {
    text-decoration: none;
}

.nav:hover {
    text-decoration: underline;
}
