
#move-in-to-place {
    position: absolute;
    bottom: -100%;
    height : 100%;
    width : 100%;
    color: white;
    background: rgba(30, 30, 67, 0.92);
    height:0;
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
}
.box {
    position: relative;
    width:100%;
    height:100%;
    overflow:hidden;
    background: white;
}
.box:hover #move-in-to-place {
    bottom: 0;
    -webkit-transition: all .8s, -webkit-transform .8s;
    transition: all .8s, transform .8s;
    width:100%;
    height:100%;
    opacity:1;
}

.box:not(hover) #move-in-to-place {
    bottom: -100%;
    -webkit-transition: all 2s, -webkit-transform 2s;
    transition: all 2s, transform 2s;
    width:100%;
    height:0;
    opacity:0;
}

.name_text {
    font-size: calc(20px + 2vh);
    color: #7d3a98;

}

.name_subtext {
    font-size: calc(10px + 1vh);
    color: black;
}
