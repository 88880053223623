.sidebar {
    width: 200px;
    height: 100vh;
    color: black;
    background-color: #1E1E44;
    border-bottom: .5px solid black;
    position: absolute;
    right: 0;
    z-index: 0;
    transition: width 1s ease-in-out;
}

.line-item {
    margin-top: 20px;
    font-size: 25px;
    color: white;
    border-bottom: .5px solid black;
    cursor: pointer;
}