
  .menu_button {
    display: none;
    position: relative;
    float: right;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    border: 3px solid white;
  }

  .burger {
    display: none;
    width: 40px;
    height: 6px;
    background: white;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  }

  .ham {
    display: none;
    position: relative;
    float: right;
    /* justify-content: center;
    align-items: center; */
    top: 30px;
    right: 8px;
    cursor: pointer;
    z-index: 10;
  }

  .burger::before,
  .burger::after {
    display: none;
    content: '';
    position: absolute;
    width: 40px;
    height: 6px;
    background: white;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  }

  .burger::before {
    transform: translateY(-16px);
  }

  .burger::after {
    transform: translateY(16px);
  }

@media (max-width: 600px) {
    .burger,
    .menu_button,
    .burger::before,
    .burger::after,
    .ham {
        display: flex;
    }
}