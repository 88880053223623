.bigVerticalButton {
    background-color: white;
    height: 60%;
    width: 100%;
}

.navButton {
    font-size: 50px;
}

.navButton:hover {
    background-color: purple;
}

.lock {
    font-size: 60px;
}

.lock:hover {
    
}
